button.button {
  width: calc(100% / 3 - calc(1px * 2 / 3));
  height: 100%;
  font-size: 7vmin;
  background-color: #c00;
  color: #fff;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button.button:disabled {
  background-color: #444;
}

button.button:active,
button.button.active {
  background-color: #f00;
}

button.button.green {
  background-color: #0c0;
}

button.button.green.active,
button.button.green:active {
  background-color: #0f0;
}

button.button.green:disabled {
  background-color: #444;
}

button.button > i.material-icons {
  font-size: 5.5vmin;
}
