@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  font-family: sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
input[type='text'],
input[type='password'] {
  user-select: text;
  -webkit-user-select: text;
}

input {
  -webkit-appearance: none;
}