#ctx {
  width: 100%;
  height: 51%;
  display: flex;
  justify-content:center;
  background-color: black;
  color: white;
  font-size: 7vmin;
  & > video {
    height: 100%;
    width: 100%;
  }
  & > canvas {
    display: none;
  }
  &.hidden{
    display: none;
  }
}
